.game-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    gap: 10px;
    justify-content: space-evenly;
}

@media only screen and (max-width: 320px) {
    .game-container{
        grid-template-columns: repeat(1, 1fr);
    }
}


@media only screen and (min-width: 768px) {
    .game-container{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 992px) {
    .game-container{
        grid-template-columns: repeat(4, 1fr);
    }
}