.card{
    background-color: var(--cor-2);
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.496);
}
.card--invisible{
    display: none;
}
.card__image-container{
    aspect-ratio: 1/1;
}
.card__image{
    display: block;
    padding: 10px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(var(--cor-1) 0% , var(--cor-2) 50%);
    object-fit: contain;
}
.card__title{
    /* font-size: 0.9em; */
    padding: 15px 0px;
    color: var(--cor-3);
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.427);
    text-align: center;
    background-image: linear-gradient(var(--cor-2), var(--cor-1));
}