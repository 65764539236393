@charset "UTF-8";

:root{
    --cor-1: #1d2e65;
    --cor-2: #4d6f94;
    --cor-3: #abc2c5;
    --cor-4: #362e42;
    --cor-5: #000000;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

body{
    background-color: var(--cor-4);
}